import "./App.css";
import { StoreProvider } from "./contexts/Store";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./utils/theme";

import { ToastContainer } from "react-toast";
import Stepper from "./components/stepper/Component";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer delay={8001} position="bottom-right" />
      <StoreProvider>
        <Stepper />
      </StoreProvider>
    </ThemeProvider>
  );
}

export default App;
