import { styled, Box } from "@mui/material";
import theme from "../../../utils/theme";

const PaymentMethodsStyle = styled(Box)(() => ({
  h1: {
    padding: `${theme.spacing(4)} 0px`,
    textAlign: "center",
    borderBottom: `1px solid ${theme.palette.color17.main}`,
  },
  ".agree-to-the-terms": {
    padding: `${theme.spacing(4)} 0px`,
  },
  ".payment-method-buttons": {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down("xxs")]: {
      paddingTop: theme.spacing(1),
    },
    ".MuiButton-root:hover": {
      backgroundColor: theme.palette.primary.main,
      ".button-title-and-icon": {
        color: theme.palette.color1.main,
      },
    },
    ".Mui-disabled": {
      border: `1px solid ${theme.palette.secondary.dark} !important`,
    },
  },
  ".credit-or-crypto-container": {
    margin: `${theme.spacing(4)} 0`,
    [theme.breakpoints.down("xxs")]: {
      margin: `${theme.spacing(2)} 0`,
    },
  },
  ".terms-and-conditions": {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    ".disclaimer-text": {
      padding: theme.spacing(1),
      margin: "2px 0",
      display: "flex",
      gap: theme.spacing(1),
    },
    ".legal-words": {
      marginTop: "20px",
    },
    [theme.breakpoints.down("xxs")]: {
      br: {
        display: "none",
      },
    },
  },
  ".button-title-and-icon": {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
  [theme.breakpoints.down("xxs")]: {
    h1: {
      fontSize: "29px",
    },
    "> .MuiTypography-root": {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
}));

export default PaymentMethodsStyle;
