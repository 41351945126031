import { Grid, styled } from "@mui/material";
import theme from "../../../utils/theme";

const CardItemStyle = styled(Grid)(() => ({
  ".card-box": {
    display: "flex",
    width: "100%",
    position: "relative",
    borderRadius: "10px",
    overflow: "hidden",
    cursor: "pointer",
    img: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },

    ".card-description-box": {
      opacity: 0,
      display: "flex",
      position: "absolute",
      width: "100%",
      minHeight: "75px",
      maxHeight: "fit-content",
      backgroundColor: "rgba(0,0,0,0.65)",
      bottom: 0,
      justifyContent: "center",
      alignItems: "center",
      transition: "opacity 0.2s linear",
      ".card-description": {
        color: theme.palette.color1.main,
        textAlign: "center",
        padding: "5px",
        textWrap: "balance",
      },
    },
    "&:hover": {
      ".card-description-box": {
        opacity: 1,
      },
    },
  },
}));

export default CardItemStyle;
