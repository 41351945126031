import { Box, styled } from "@mui/material";

const CardsStyle = styled(Box)(() => ({
  ".cards-title": {
    paddingTop: "30px",
    paddingBottom: "30px",
  },
  ".cards-container": {
    display: "flex",
  },
}));

export default CardsStyle;
