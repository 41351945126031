/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import PaymentMethodsStyle from "./Style";
import { Typography } from "@mui/material";

import { useStoreContext } from "../../../hooks/Contexts";

import { componentTracking } from "../../../utils/utils";
import Cards from "../../cards/Component";
import useContractService from "../../../services/Contract";
import { TokenURIsModel } from "../../../models/card";
import TransactionLoader from "../../transaction-loader/Component";
import ButtonBlue from "../../form/button-blue/Component";

const Wallet = () => {
  const { store, setStore } = useStoreContext();
  const { getTokenUris, loading } = useContractService();
  const [tokenURIs, setTokenURIs] = useState<TokenURIsModel>({
    normal: [],
    special: [],
    dinner: [],
    commemorativeDinner: [],
    oneOfOne: [],
  });

  useEffect(() => {
    componentTracking("checkout", {
      checkout_view: "WALLET",
    });

    (async () => {
      //const res = await getTokenUris("0x5a92B989885e06a5A7236d878A195592aaC8A576");
      const res = await getTokenUris(store.cart.user.wallet);
      setTokenURIs(res);
    })();
  }, []);

  useEffect(() => {
    localStorage.removeItem("signIn");
    setStore((prevState) => ({
      ...prevState,
      flowControl: {
        ...prevState.flowControl,
        backStep: {
          ...prevState.flowControl.steps.getStarted,
          title: "Get Started",
        },
      },
      cart: {
        ...prevState.cart,
        quantity: 1,
      },
    }));
  }, []);

  const handleContinue = () => {
    window.open("https://www.collecttrumpcards.com/", "_blank", "noopener,noreferrer");
  };

  if (loading) {
    return <TransactionLoader type="Simple Loading" />;
  }

  return (
    <PaymentMethodsStyle>
      {tokenURIs.normal.length === 0 &&
      tokenURIs.special.length === 0 &&
      tokenURIs.dinner.length === 0 &&
      tokenURIs.oneOfOne.length === 0 &&
      tokenURIs.commemorativeDinner.length === 0 ? (
        <>
          <Typography variant="h1">
            Please visit CollectTrumpCards.com to see our AMAZING PACKAGES!{" "}
          </Typography>
          <ButtonBlue
            props={{
              variant: "contained",
              style: {
                display: "block",
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto",
              },
              fullWidth: false,
            }}
            onClick={handleContinue}
          >
            Visit CollectTrumpCards.com
          </ButtonBlue>
        </>
      ) : (
        <>
          <Typography variant="h1">Here Are Your Trump Digital Trading Cards</Typography>
          <Cards URIs={tokenURIs} />
        </>
      )}
    </PaymentMethodsStyle>
  );
};

export default Wallet;
